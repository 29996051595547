import { createAsyncAction } from "typesafe-actions"
import {
    HideCommentActionTypes,
    GetCommentsActionTypes,
    GetRepliesActionTypes,
    GetShortAnalysisListActionTypes,
    GetSubEvidenceActionTypes,
    PostCommentActionTypes,
    PostEditedCommentActionTypes,
    PostReplyActionTypes,
    PostReportActionTypes,
    Replies,
    ReportRequest,
    ShortAnalysisListParams,
    TopAnalysisActionTypes,
    TopUnpublishedAnalysisActionTypes,
    VoteActionTypes,
    GetAnalysisActionTypes,
    UserEditCommentActionTypes,
    GetSensitivityCalculationActionTypes,
} from "./types"
import { Analise, Comment, EvidenceType, Story, GetSensitivityCalcResponseTypes } from "src/types"
import { Dispatch, SetStateAction } from "react"
import { FormikValues } from "formik"

export const getTopAnalysisActions = createAsyncAction(
    TopAnalysisActionTypes.TOP_ANALYSIS_REQUEST,
    TopAnalysisActionTypes.TOP_ANALYSIS_SUCCESS,
    TopAnalysisActionTypes.TOP_ANALYSIS_FAILURE
)<{ device: "tablet" | "mobile" | "desktop" }, Analise[], { error: any }>()

export const getTopUnpublishedAnalysisActions = createAsyncAction(
    TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_REQUEST,
    TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_SUCCESS,
    TopUnpublishedAnalysisActionTypes.TOP_UNPUBLISHED_ANALYSIS_FAILURE
)<{ device: "tablet" | "mobile" | "desktop" }, Analise[], { error: any }>()

export const getCommentsActions = createAsyncAction(
    GetCommentsActionTypes.GET_COMMENTS_REQUEST,
    GetCommentsActionTypes.GET_COMMENTS_SUCCESS,
    GetCommentsActionTypes.GET_COMMENTS_FAILURE
)<
    {
        limit?: number
        offset: number
        slug: string
        type?: "claims" | "inference_objects"
    },
    { comments: Comment[]; commentsCount: number; replies: Replies },
    { error: any }
>()

export const postCommentActions = createAsyncAction(
    PostCommentActionTypes.POST_COMMENT_REQUEST,
    PostCommentActionTypes.POST_COMMENT_SUCCESS,
    PostCommentActionTypes.POST_COMMENT_FAILURE
)<
    {
        slug: string
        body: { text: string }
        type?: "inference_objects" | "claims"
        resetForm: () => void
    },
    Comment,
    { error: any }
>()

export const postReplyActions = createAsyncAction(
    PostReplyActionTypes.POST_REPLY_REQUEST,
    PostReplyActionTypes.POST_REPLY_SUCCESS,
    PostReplyActionTypes.POST_REPLY_FAILURE
)<
    {
        body: { text: string; commentId: number }
        setLoading: Dispatch<SetStateAction<boolean>>
        resetForm?: ((value: FormikValues) => void) | undefined
        currentUserId: number | undefined
    },
    { replies: Replies; comments: Comment[] },
    { error: any }
>()

export const getRepliesActions = createAsyncAction(
    GetRepliesActionTypes.GET_REPLIES_REQUEST,
    GetRepliesActionTypes.GET_REPLIES_SUCCESS,
    GetRepliesActionTypes.GET_REPLIES_FAILURE
)<
    { id: number; setLoading: (value: boolean) => void; limit: number; offset: number },
    Replies,
    { error: any }
>()

export const voteRequestActions = createAsyncAction(
    VoteActionTypes.VOTE_REQUEST,
    VoteActionTypes.VOTE_SUCCESS,
    VoteActionTypes.VOTE_FAILURE
)<
    { id: number; vote_flag: boolean; type: "comment" | "reply" },
    { comments: Comment[]; replies: Replies },
    { error: any }
>()

export const postReportActions = createAsyncAction(
    PostReportActionTypes.POST_REPORT_REQUEST,
    PostReportActionTypes.POST_REPORT_SUCCESS,
    PostReportActionTypes.POST_REPORT_FAILURE
)<ReportRequest, { result: null }, { error: any }>()

export const getSubEvidenceActions = createAsyncAction(
    GetSubEvidenceActionTypes.GET_SUB_EVINCE_REQUEST,
    GetSubEvidenceActionTypes.GET_SUB_EVINCE_SUCCESS,
    GetSubEvidenceActionTypes.GET_SUB_EVINCE_FAILURE
)<
    {
        id: number
        setSubEvidence: Dispatch<SetStateAction<EvidenceType[] | null>>
    },
    EvidenceType,
    { error: any }
>()

export const getShortAnalysisActions = createAsyncAction(
    GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_REQUEST,
    GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_SUCCESS,
    GetShortAnalysisListActionTypes.GET_SHORT_ANALYSIS_FAILURE
)<
    ShortAnalysisListParams,
    { main_page_stories: Story[]; main_page_stories_counter: number },
    { error: any }
>()

export const postEditedCommentActions = createAsyncAction(
    PostEditedCommentActionTypes.POST_EDITED_COMMENT_REQUEST,
    PostEditedCommentActionTypes.POST_EDITED_COMMENT_SUCCESS,
    PostEditedCommentActionTypes.POST_EDITED_COMMENT_FAILURE
)<
    {
        body: { text: string; commentId: number }
        setLoading: Dispatch<SetStateAction<boolean>>
        setEditingComment: Dispatch<SetStateAction<boolean>>
        resetForm?: ((value: FormikValues) => void) | undefined
        type: string
    },
    { replies: Replies; comments: Comment[] },
    { error: any }
>()

export const hideCommentActions = createAsyncAction(
    HideCommentActionTypes.HIDE_COMMENT_REQUEST,
    HideCommentActionTypes.HIDE_COMMENT_SUCCESS,
    HideCommentActionTypes.HIDE_COMMENT_FAILURE
)<
    { id: number; type: string; commentId?: number },
    { replies: Replies; comments: Comment[]; commentsCount: number },
    { error: any }
>()

export const GetAnalysisActions = createAsyncAction(
    GetAnalysisActionTypes.GET_ANALYSIS_REQUEST,
    GetAnalysisActionTypes.GET_ANALYSIS_SUCCESS,
    GetAnalysisActionTypes.GET_ANALYSIS_FAILURE
)<
    { slug?: string; type: "inference_objects" | "claims" },
    { analise: Analise; comments: Comment[]; commentsCount: number; replies: Replies },
    { error: any }
>()

export const userEditCommentActions = createAsyncAction(
    UserEditCommentActionTypes.USER_EDIT_COMMENT_REQUEST,
    UserEditCommentActionTypes.USER_EDIT_COMMENT_SUCCESS,
    UserEditCommentActionTypes.USER_EDIT_COMMENT_FAILURE
)<
    {
        body: { text: string; commentId?: number }
        setLoading: Dispatch<SetStateAction<boolean>>
        setEditingComment: Dispatch<SetStateAction<boolean>>
        resetForm?: ((value: FormikValues) => void) | undefined
        type: "comment" | "reply"
    },
    { replies: Replies; comments: Comment[] },
    { error: any }
>()

export const getSensitivityCalculationActions = createAsyncAction(
    GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_REQUEST,
    GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_SUCCESS,
    GetSensitivityCalculationActionTypes.GET_SENSITIVITY_CALCULATION_FAILURE
)<{ id: number }, GetSensitivityCalcResponseTypes, { error: any }>()
