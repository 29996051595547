import { useDispatch } from "react-redux"

// components
import ButtonPrimary from "src/reusableComponents/ButtonPrimary"

// utils
import { removeModal } from "../../../../redux/actions/ui/ui"
import { SUCCESS_LOGIN_MODAL } from "src/constants/modals"
import { removePopup } from "src/redux/actions/user/user"

// styles
import { StyledBodyMain } from "src/styles/Text/Text.styles"
import { StyledSuccessModal } from "./SuccessLoginModal.styles"

const SuccessLoginModal = () => {
    const dispatch = useDispatch()

    const onContinue = () => {
        dispatch(removeModal(SUCCESS_LOGIN_MODAL))
        dispatch(removePopup())
    }

    return (
        <StyledSuccessModal>
            <StyledBodyMain weight={600}>Congratulations!</StyledBodyMain>
            <StyledBodyMain weight={600}>You've logged in!</StyledBodyMain>
            <div className="buttonsBlock">
                <ButtonPrimary block onClick={onContinue}>
                    Continue
                </ButtonPrimary>
            </div>
        </StyledSuccessModal>
    )
}

export default SuccessLoginModal
