import {
    Analise,
    BlogPost,
    Claim,
    Comment,
    SensitivityCalcItem,
    Story,
    Tweet,
} from "../../../types"

export type Replies = { [key: number]: Comment[] }

export type ReportRequest = {
    category: number
    id: number
    idType: "suggestion_id" | "comment_id" | "reply_id"
    other_text?: string
}

export type MainReducerState = {
    main_page_stories?: Story[]
    main_page_stories_counter?: number
    mainPageLoader: boolean
    last_blog_posts?: BlogPost[]
    tweets?: Tweet[]
    analise?: Analise
    claim?: Claim
    comments: Comment[]
    commentsLoader: boolean
    commentsCount: number
    commentInputLoader: boolean
    replies: Replies
    topAnalyses: Story[]
    topAnalysesLoader: boolean
    sensitivityCalculation: SensitivityCalcItem[] | null
}

export type ShortAnalysisListParams = {
    limit: number
    offset: number
    device: "mobile" | "tablet" | "desktop"
}

export enum TopAnalysisActionTypes {
    TOP_ANALYSIS_REQUEST = "@@main/TOP_ANALYSIS_REQUEST",
    TOP_ANALYSIS_SUCCESS = "@@main/TOP_ANALYSIS_SUCCESS",
    TOP_ANALYSIS_FAILURE = "@@main/TOP_ANALYSIS_FAILURE",
}

export enum TopUnpublishedAnalysisActionTypes {
    TOP_UNPUBLISHED_ANALYSIS_REQUEST = "@@main/TOP_UNPUBLISHED_REQUEST",
    TOP_UNPUBLISHED_ANALYSIS_SUCCESS = "@@main/TOP_UNPUBLISHED_SUCCESS",
    TOP_UNPUBLISHED_ANALYSIS_FAILURE = "@@main/TOP_UNPUBLISHED_FAILURE",
}

export enum GetCommentsActionTypes {
    GET_COMMENTS_REQUEST = "@@main/GET_COMMENTS_REQUEST",
    GET_COMMENTS_SUCCESS = "@@main/GET_COMMENTS_SUCCESS",
    GET_COMMENTS_FAILURE = "@@main/GET_COMMENTS_FAILURE",
}

export enum PostCommentActionTypes {
    POST_COMMENT_REQUEST = "@@main/POST_COMMENT_REQUEST",
    POST_COMMENT_SUCCESS = "@@main/POST_COMMENT_SUCCESS",
    POST_COMMENT_FAILURE = "@@main/POST_COMMENT_FAILURE",
}

export enum PostReplyActionTypes {
    POST_REPLY_REQUEST = "@@main/POST_REPLY_REQUEST",
    POST_REPLY_SUCCESS = "@@main/POST_REPLY_SUCCESS",
    POST_REPLY_FAILURE = "@@main/POST_REPLY_FAILURE",
}

export enum GetRepliesActionTypes {
    GET_REPLIES_REQUEST = "@@main/GET_REPLIES_REQUEST",
    GET_REPLIES_SUCCESS = "@@main/GET_REPLIES_SUCCESS",
    GET_REPLIES_FAILURE = "@@main/GET_REPLIES_FAILURE",
}

export enum VoteActionTypes {
    VOTE_REQUEST = "@@main/VOTE_REQUEST",
    VOTE_SUCCESS = "@@main/VOTE_SUCCESS",
    VOTE_FAILURE = "@@main/VOTE_FAILURE",
}

export enum PostReportActionTypes {
    POST_REPORT_REQUEST = "@@main/POST_REPORT_REQUEST",
    POST_REPORT_SUCCESS = "@@main/POST_REPORT_SUCCESS",
    POST_REPORT_FAILURE = "@@main/POST_REPORT_FAILURE",
}

export enum GetSubEvidenceActionTypes {
    GET_SUB_EVINCE_REQUEST = "@@main/GET_SUB_EVINCE_REQUEST",
    GET_SUB_EVINCE_SUCCESS = "@@main/GET_SUB_EVINCE_SUCCESS",
    GET_SUB_EVINCE_FAILURE = "@@main/GET_SUB_EVINCE_FAILURE",
}

export enum GetShortAnalysisListActionTypes {
    GET_SHORT_ANALYSIS_REQUEST = "@@main/GET_SHORT_ANALYSIS_REQUEST",
    GET_SHORT_ANALYSIS_SUCCESS = "@@main/GET_SHORT_ANALYSIS_SUCCESS",
    GET_SHORT_ANALYSIS_FAILURE = "@@main/GET_SHORT_ANALYSIS_FAILURE",
}

export enum PostEditedCommentActionTypes {
    POST_EDITED_COMMENT_REQUEST = "@@main/POST_EDITED_COMMENT_REQUEST",
    POST_EDITED_COMMENT_SUCCESS = "@@main/POST_EDITED_COMMENT_SUCCESS",
    POST_EDITED_COMMENT_FAILURE = "@@main/POST_EDITED_COMMENT_FAILURE",
}

export enum HideCommentActionTypes {
    HIDE_COMMENT_REQUEST = "@@main/HIDE_COMMENT_REQUEST",
    HIDE_COMMENT_SUCCESS = "@@main/HIDE_COMMENT_SUCCESS",
    HIDE_COMMENT_FAILURE = "@@main/HIDE_COMMENT_FAILURE",
}

export enum GetAnalysisActionTypes {
    GET_ANALYSIS_REQUEST = "@@main/GET_ANALYSIS_REQUEST",
    GET_ANALYSIS_SUCCESS = "@@main/GET_ANALYSIS_SUCCESS",
    GET_ANALYSIS_FAILURE = "@@main/GET_ANALYSIS_FAILURE",
}

export enum UserEditCommentActionTypes {
    USER_EDIT_COMMENT_REQUEST = "@@main/USER_EDIT_COMMENT_REQUEST",
    USER_EDIT_COMMENT_SUCCESS = "@@main/USER_EDIT_COMMENT_SUCCESS",
    USER_EDIT_COMMENT_FAILURE = "@@main/USER_EDIT_COMMENT_FAILURE",
}

export enum GetSensitivityCalculationActionTypes {
    GET_SENSITIVITY_CALCULATION_REQUEST = "@@main/GET_SENSITIVITY_CALCULATION_REQUEST",
    GET_SENSITIVITY_CALCULATION_SUCCESS = "@@main/GET_SENSITIVITY_CALCULATION_SUCCESS",
    GET_SENSITIVITY_CALCULATION_FAILURE = "@@main/GET_SENSITIVITY_CALCULATION_FAILURE",
}
