import { SUCCESS_LOGIN_MODAL } from "../../../../constants/modals"
import SuccessLoginModal from "./SuccessLoginModal"

// types
import { ModalPropsType } from "src/redux/actions/ui/types"

export const openSuccessLoginModal: ModalPropsType = {
    id: SUCCESS_LOGIN_MODAL,
    size: "auto",
    children: <SuccessLoginModal />,
    hideCross: true,
}
