export type AdminReducerState = {
    access_token: string
    email: string
    id: number | null
    list: AdminList
    loading: boolean
    adminView: AdminUserView | null
}

export type AdminResponse = {
    access_token: string
    email: string
}

export type AdminListItem = {
    id: number
    email: string
    last_name: string
    first_name: string
    current_sign_in_at: Date
    last_sign_in_at: Date
    sign_in_count: number
}

export type AdminList = {
    page: number | undefined
    count: number
    data: AdminListItem[]
}

export type AdminUser = {
    id?: number
    email: string
    last_name: string
    first_name: string
    password: string
}

export type AdminUserView = {
    id: number
    current_sign_in_at: Date | null
    current_sign_in_ip: string
    email: string
    encrypted_password: string
    first_name: string | null
    last_name: string | null
    last_sign_in_at: Date | null
    last_sign_in_ip: string | null
    rails_admin_access: null | boolean
    remember_created_at: Date | null
    reset_password_token: string | null
    sign_in_count: number | null
}

export enum LoginActionTypes {
    LOGIN_REQUEST = "@@admin/admin/LOGIN_REQUEST",
    LOGIN_SUCCESS = "@@admin/admin/LOGIN_SUCCESS",
    LOGIN_FAILURE = "@@admin/admin/LOGIN_FAILURE",
}

export enum LogoutActionType {
    LOGOUT = "@admin/LOGOUT",
}

export enum GetAdminUserListActionTypes {
    GET_ADMIN_USER_LIST_REQUEST = "@@admin/admin/GET_ADMIN_USER_LIST_REQUEST",
    GET_ADMIN_USER_LIST_SUCCESS = "@@admin/admin/GET_ADMIN_USER_LIST_SUCCESS",
    GET_ADMIN_USER_LIST_FAILURE = "@@admin/admin/GET_ADMIN_USER_LIST_FAILURE",
}

export enum GetAdminInfoActionTypes {
    GET_ADMIN_INFO_REQUEST = "@@admin/admin/GET_ADMIN_INFO_REQUEST",
    GET_ADMIN_INFO_SUCCESS = "@@admin/admin/GET_ADMIN_INFO_SUCCESS",
    GET_ADMIN_INFO_FAILURE = "@@admin/admin/GET_ADMIN_INFO_FAILURE",
}

export enum DeleteAdminActionTypes {
    DELETE_ADMIN_REQUEST = "@@admin/admin/DELETE_ADMIN_REQUEST",
    DELETE_ADMIN_SUCCESS = "@@admin/admin/DELETE_ADMIN_SUCCESS",
    DELETE_ADMIN_FAILURE = "@@admin/admin/DELETE_ADMIN_FAILURE",
}
