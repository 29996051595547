import { createAction, createAsyncAction } from "typesafe-actions"
import {
    AdminList,
    AdminResponse,
    AdminUserView,
    DeleteAdminActionTypes,
    GetAdminInfoActionTypes,
    GetAdminUserListActionTypes,
    LoginActionTypes,
    LogoutActionType,
} from "./types"
import { LoginRequest } from "../../user/types"
import { FormikErrors } from "formik"

export const adminLoginActions = createAsyncAction(
    LoginActionTypes.LOGIN_REQUEST,
    LoginActionTypes.LOGIN_SUCCESS,
    LoginActionTypes.LOGIN_FAILURE
)<
    LoginRequest & { setErrors: (errors: FormikErrors<LoginRequest>) => void },
    AdminResponse,
    { error: any }
>()

export const adminLogoutAction = createAction(LogoutActionType.LOGOUT)<undefined>()

export const getAdminUserListActions = createAsyncAction(
    GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_REQUEST,
    GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_SUCCESS,
    GetAdminUserListActionTypes.GET_ADMIN_USER_LIST_FAILURE
)<{ limit: number; page: number }, AdminList, { error: any }>()

export const getAdminInfoActions = createAsyncAction(
    GetAdminInfoActionTypes.GET_ADMIN_INFO_REQUEST,
    GetAdminInfoActionTypes.GET_ADMIN_INFO_SUCCESS,
    GetAdminInfoActionTypes.GET_ADMIN_INFO_FAILURE
)<number | string, AdminUserView, { error: any }>()

export const deleteAdminActions = createAsyncAction(
    DeleteAdminActionTypes.DELETE_ADMIN_REQUEST,
    DeleteAdminActionTypes.DELETE_ADMIN_SUCCESS,
    DeleteAdminActionTypes.DELETE_ADMIN_FAILURE
)<
    { id: number | string; action?: () => void; page?: number; limit?: number | undefined },
    undefined,
    { error: any }
>()
